@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
    --container: 1315px;
    --blue: #0072C5;
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
* {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;

}
body {
    margin: 0;
    box-sizing: border-box;
    font-size: 18px;
}

/* Default Styles */
.g-section__tag {
    position: relative;
    width: 100%;
    padding-left: 64px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--blue);
}
.g-section__tag::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 50px;
    height: 1px;
    background-color: var(--blue);
}
.g-section__tag.white {
    color: #fff;
}
.g-section__tag.white::before {
    background-color: #fff;
}
.g-section__heading {
    width: 70%;
    font-size: 46px;
    font-weight: 300;
    margin: 0 0 30px;
    padding-left: 64px;
}
.g-section__text {
    font-size: 18px;
    padding-left: 64px;
}
.g-section__actions {
    width: 30%;
    justify-content: flex-end;
    align-items: center;
    height: fit-content;
}
.g-sa__link {
    font-size: 12px;
    color: #B6B6B6;
    margin-right: 30px;
}
.g-section .g-slider__navigation {
    border: 1px solid #E6E6E6;
}
.g-section .g-bsn__btn path {
    stroke: #000;
}
.g-section  .g-bsn__btn.prev {
    border-color: #E6E6E6;
}

ul {
    list-style-type: none;
}
a {
    text-decoration: none;
}
input {
    outline: none;
}
/* Glob Styles */
.g-container{
    max-width: var(--container);
    margin-left: auto;
    margin-right: auto;
}
.g-row{
    padding-right: 15px;
    padding-left: 15px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.g-position_relative {
    position: relative; 
}
.g-dfw{
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.g-alt-center{
    align-items: center;
}
.g-jc-center{
    justify-content: center;
}
.g-jc-sb{
    justify-content: space-between;
}
.g-h-fit{
    height: fit-content;
}
.g-w40p {
    width: 40%;
    padding-right: 60px;
}
.g-w60p {
    width: 60%;
}
/* Buttons Styles */
.g-btn {
    color: #000;
    cursor: pointer;
    background: #F5F5F5;
    border-radius: 6px;
    border-width: 1px;
    border-style: solid;
    border-color: #F5F5F5;
    font-size: 14px;
    padding: 15px;
    transition: all .3s ease-in;
    text-align: center;
}
.g-btn.g-btn__white {
    background-color: #fff;
    border-color: #fff;
}
.g-btn.g-btn__white:hover {
    color: #fff;
    background-color: transparent;
}
.g-btn.g-btn__white:hover path {
    /* stroke: #fff */
}
.g-btn.g-btn__transparent {
    background-color: transparent;
    border-color: #fff;
    color: #fff;
}
.g-btn.g-btn__transparent:hover {
    background-color: #fff;
    color: #000;
}
.g-btn.g-btn__transparent:hover path {
    /* stroke: #000; */
}
.g-btn__transparent-gray {
    background-color: transparent;
    border-color: #E1ECEF;
    color: #8D9BA5;
}
.g-btn__transparent-gray:hover {
    background-color: #E1ECEF;
    color: #000;
}
.g-btn.g-btn__blue {
    background-color: #0072C5;
    border-color: #0072C5;
    color: #fff;
}
.g-btn.g-btn__blue:hover {
    background-color: #fff;
    color: var(--blue);
}
.g-btn.g-btn__transparent-blue {
    background-color: transparent;
    border-color: #0072C5;
    color: #0072C5;
}
.g-btn.g-btn__transparent-blue:hover {
    background-color: var(--blue);
    color: #fff;
}
.g-btn.g-btn__black {
    background-color: #000;
    border-color: #000;
    color: #fff;
}
.g-btn.g-btn__black:hover {
    background-color: #fff;
    color: #000;
}
.g-btn.disabled {
    border-color: var(--secondary);
    background-color: var(--bg);
    color: var(--secondary);
    pointer-events: none;
}
.g-btn.rounded {
    border-radius: 50%;
}
.g-bage {
    padding: 5px 10px;
    margin: 0;
    border-radius: 6px;
    background-color: #B6B6B6;
    border: 1px solid;
}
.g-bage.gray {
    background: #E9F1F5;
    border-radius: 4px;
    border-color: #E9F1F5;
    color: #8D9BA5;
    font-size: 12px;
    width: fit-content;
}
.g-bage.dark-gray {
    background: #8D979D;
    border-color: #8D979D;
    color: #fff;
}
.g-bage.white {
    border-color: #fff;
    background-color: #fff;
    color: #8D9BA5;
}
.g-bage.blue {
    background-color: var(--blue);
    border-color: var(--blue);
    color: #fff;
}
.g-bage.red {
    background-color: #DA3F3F;
    border-color: #DA3F3F;
    color: #fff;
}
.g-bage.black {
    background-color: #000;
    border-color: #000;
    color: #fff;
}
.g-bage.green {
    background-color: #5AC257;
    border-color: #5AC257;
    color: #fff;
}
.g-bage.transparent {
    background-color: transparent;
    border-color: #E1ECEF;
    color: #000;
}
/* Swiper Slider Styles */
.swiper {
    width: 100%;
    height: 100%;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    cursor: pointer;
}
.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.g-slider__navigation {
    border: 1px solid rgb(255 255 255 / 30%);
    border-radius: 6px;
    height: fit-content;
}
.g-bsn__btn {
    padding: 13px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.g-bsn__btn.prev {
    border-right: 1px solid rgb(255 255 255 / 30%);
}
/* Posts Styles */
.g-post__banner {
    width: 100%;
    height: 260px;
    position: relative;
    margin-bottom: 25px;
    overflow: hidden;
}
.g-post__banner img {
    border-radius: 6px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.g-post_excerpt {
    width: 100%;
    align-items: flex-end;
}
.g-pe__icon {
    margin-right: 25px;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.g-pe__wrap {
    width: calc(100% - 80px);
    text-align: left;
}
.g-pe__title {
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 5px;
    color: #000;
}
.g-pe__text {
    font-size: 10px;
    color: #B6B6B6;
    margin: 0;
}
.g-post__meta {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 10px;
}
/* Pop Up Styles */
/*PopUp Stylesheet*/
@keyframes pop-up {
    0%{
        opacity: 0;
        transform: scale(.8);
    }
    50%{
        opacity: 1;
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
}
@-webkit-keyframes pop-up {
    0%{
        opacity: 0;
        transform: scale(.8);
    }
    50%{
        opacity: 1;
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
}
.g-pop_ups {
    margin: 0;
}
.g-pop_up {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 30px 15px;
    box-sizing: border-box;
    background-color: rgb(20 20 20 / 39%);
    overflow-y: overlay;
    z-index: -1;
    opacity: 0;
    transition: all .3s ease;
}
.g-pop_up.active {
    opacity: 1;
    z-index: 25;
    animation: .2s pop-up ease-in;
}
.g-pop_up__wrap {
    position: relative;
    width: 760px;
    max-width: 100%;
    margin: auto auto;
    background: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 60px 70px;
    border-radius: 6px;
}
.g-pop_up__wrap__header {
    margin-bottom: 60px;
}
.g-pop_up__wrap__header h4 {
    font-size: 36px;
    font-weight: normal;
    color: var(--blue);
    margin: 0;
}
.g-pop_up__wrap__header .fa-close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #E3E3E3;
    font-size: 24px;
    cursor: pointer;
}
.g-pop_up__btn {
    cursor: pointer;
}
/* Tabination Styles */
.g-tabination {
    width: 100%;
    position: relative;
}

.g-tab_navigation:not(.reset) {
    width: 100%;
}
.g-tn__item:not(.reset) {
    font-size: 14px;
    font-weight: 600;
    color: #8D9BA5;
    width: calc(25% - 7.5px);
    border-bottom: 1px solid#E1ECEF;
    padding-bottom: 10px;
    cursor: pointer;
}
.g-tn__item svg {
    margin-right: 10px;
}
.g-tn__item:not(:last-child) {
    margin-right: 10px;
}
.g-tn__item.active:not(.reset) {
    border-bottom-color: var(--blue);
    color: #000;
}
.g-tn__item.active:not(.reset) path {
    stroke: var(--blue);
}
.g-tab:not(.reset) {
    position: absolute;
    width: 100%;
    opacity: 0;
    z-index: -1;
    transition: all .3s ease;
    pointer-events: none;
}
.g-tab.active:not(.reset) {
    position: static;
    opacity: 1;
    z-index: auto;
    pointer-events: auto;
}
.g-tab__heading {
    width: 100%;
    font-weight: 600;
    font-size: 20px;
    color: var(--blue);
    margin: 25px 0;
}
/* Form Styles */
.g-form {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
}
.g-f__label:not(.reset) {
    position: relative;
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #E1ECEF;
    border-radius: 6px;
    margin-bottom: 15px;
}
.g-f__label.error {
    border: 1px solid #ed1c1c;
}
.g-f__label.error::after {
    content: attr(data-error);
    position: absolute;
    color: #ed1c1c;
    font-size: 12px;
    top: 100%;
    left: 0;
}
.g-f__label.w-33 {
    width: calc(33.33335% - 10px);
}
.g-f__label.w-33:not(:nth-child(3n)) {
    margin-right: 15px;
}
.g-f__label span:not(.reset) {
    width: 100%;
    display: block;
    font-weight: 500;
    font-size: 12px;
    color: #A8B4BD;
    margin-bottom: 8px;
}
.g-f__label input:not([type="checkbox"]), .g-f__label select, .g-f__label textarea {
    width: 100%;
    border: none;
    color: #000;
    font-weight: 500;
    font-size: 16px;
    appearance: none;
    outline: none;
}
.g-f__label textarea {
    min-width: 100%;
}
.g-f__label input[type="date"]::-webkit-inner-spin-button,
.g-f__label input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
    appearance: none;
}
.g-f__label input::placeholder {
    color: #A8B4BD;
}
.g-fl__wrap {
    width: calc(100% - 34px);
    margin-right: 10px;
}
.g-f__label.agreement .g-fl__wrap {
    margin-right: 0;
    margin-left: 10px;
}
.g-f__label.agreement .g-fl__wrap span {
    margin: 0;
}
.g-f__label.agreement .g-fl__wrap span a {
    color: #A8B4BD;
    text-decoration: underline;
}
.g-f__label.agreement input[type="checkbox"] {
    width: 13px;
    height: 13px;
    margin: 0 0 ;
}
.g-f__label.g-hi {
    display: flex;
    flex-wrap: wrap;
}
.g-f__label svg {
    width: 24px;
    height: 24px;
    margin: auto 0;
}
.g-f__btn {
    font-size: 16px;
}
.g-f__btn:not(.w-33) {
    width: 100%;
}
.g-f__label.dashed {
    border-style: dashed;
}

.g-f__label.border-blue {
    border-color: var(--blue);
}
.g-f__label.file input {
    width: 0;
    height: 0;
    opacity: 0;
}
.g-f__label.file svg:not(.g-fl_action) {
    width: 24px;
}

.g-f__label.file svg {
    margin: 0;
}

.g-f__label.file h4 {
    margin: auto 0 auto 10px;
    font-size: 14px;
    width: calc(100% - 280px - 24px - 16px - 20px);
}

.g-f__label.file h4.blue {
    color: var(--blue);
}

.g-f__label.file p {
    margin: auto 0 auto auto;
    font-size: 10px;
    color: #B2BCC3;
    width: 280px;
    text-align: right;
}

.g-f__label.file svg.g-fl_action {
    width: 16px;
    height: 16px;
    margin: auto 0;
    margin-left: 10px;
}



.g-switch-slider {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;
}
.g-switch-slider input {
opacity: 0;
width: 0;
height: 0;
}
.g-sws__slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #ccc;
-webkit-transition: .4s;
transition: .4s;
}
.g-sws__slider:before {
position: absolute;
content: "";
height: 16px;
width: 16px;
left: 3px;
bottom: 3px;
background-color: white;
-webkit-transition: .4s;
transition: .4s;
}
.g-sws__inp:checked + .g-sws__slider {
background-color: var(--blue);
}
.g-sws__inp:focus + .g-sws__slider {
box-shadow: 0 0 1px var(--blue);
}
.g-sws__inp:checked + .g-sws__slider:before {
-webkit-transform: translateX(18px);
-ms-transform: translateX(18px);
transform: translateX(18px);
}
.g-sws__slider.round {
border-radius: 34px;
}
.g-sws__slider.round:before {
border-radius: 50%;
}

/* CheckBox Styles */
.g-ckbx__container {
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .g-ckbx__container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .g-ckbx__chmrk {
    position: absolute;
    top: 0;
    right: 0;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: #eee;
  }
  .g-ckbx__container:hover input ~ .g-ckbx__chmrk {
    background-color: #ccc;
  }
  .g-ckbx__container input:checked ~ .g-ckbx__chmrk {
    background-color: #2196F3;
  }
  .g-ckbx__chmrk:after {
    content: "";
    position: absolute;
    display: none;
  }
  .g-ckbx__container input:checked ~ .g-ckbx__chmrk:after {
    display: block;
  }
  .g-ckbx__container .g-ckbx__chmrk:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }


/* Navigation Styles */

.g-nav {
    position: absolute;
    background-color: var(--bg);
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    width: 100%;
    padding: 15px 0;
    z-index: 11;
    transition: all .3s ease;
}
.g-nav.fix {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--blue);
    box-shadow: 0 0 15px -10px #fff;
    margin: 0;
}
.g-nav.fix .g-mega-menu__content {
    position: fixed;
}
.g-nav.mega, .g-nav.mega .g-mm__search{
    background-color: var(--blue);
}
.g-nav.mobile {
    display: none;
}
.g-logo {
    width: 115px;
}
.g-logo img {
    width: 100%;
}
.g-banner__section {
    background-color: #eee;
    padding-top: 120px;
}
.g-banner__heading {
    font-size: 46px;
    font-weight: 400;
    color: #fff;
    margin: 25px 0;
}
.g-mega-menu {
    margin-right: 60px;
}
.g-mega-menu > svg {
    cursor: pointer;
}
.g-mega-menu > svg:first-child {
    margin-right: 25px;
}
.g-mega-menu__content {
    position: absolute;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    padding: 45px 0;
    width: 100%;
    left: 0;
    top: 120px;
    background-color: var(--blue);
    transition: opacity .2s ease-in-out;
}
.g-mega-menu.active .g-mega-menu__content {
    opacity: 1;
    z-index: 15;
    pointer-events: all;
}
.g-mega-menu__content .g-container {
    width: 100%;
}
.g-mm__search {
    position: absolute;
    overflow: hidden;
    width: calc(var(--container) - 90px);
    height: 118px;
    top: 0;
    left: calc((100vw - var(--container)) / 2 + 80px);
    padding: 25px 15px;
    z-index: -1;
    opacity: 0;
    transition: .2s ease-in-out;
}
.g-mega-menu.active .g-mm__search {
    opacity: 1;
    z-index: auto;
}
.g-mm__search .g-btn {
    height: fit-content;
    margin: auto 0 auto auto;
}
.g-mm__search .g-btn span {
    margin-right: 60px;
}
.g-mm__search .g-label {
    width: calc(100% - 280px);
    margin: auto 0;
}
.g-mm__search .g-label input {
    border: none;
    width: 100%;
    background-color: transparent;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
}
.g-mm__search .g-label input::placeholder {
    color: #fff5;
}

#header .g-mmc__col  * {
    color: #fff;
}
#header .g-mmcc_heading {
    font-size: 21px;
    margin: 0 0 10px;
}
#header .g-mmcc__links-list li {
    font-weight: 500;
}
#header .g-mmcc__text {
    margin-bottom: 20px;
}
.g-menu {
    margin: 0 65px;
}
.g-menu a {
    font-size: 16px;
    color: #fff;
    margin-right: 26px;
}
.g-menu a:last-child {
    margin-right: 0;
}
.g-auth .g-auth__item:first-child:not(.loged) {
    margin-right: 10px;
}
.g-auth__item {
    padding-right: 25px;
    padding-left: 25px;
    display: inline-block;
}
/* Mobile Menu Styles */
.g-m_side-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 40%;
    min-width: 300px;
    height: 100%;
    background-color: #fff;
    z-index: 10;
    overflow-y: overlay;
    padding: 20px 30px;
    transform: translateX(-100%);
    transition: all .2s ease-in-out;
}
.g-m_side-bar.open {
    transform: translateX(0);
}
.g-m_overlay {
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    background-color: rgb(0 0 0 / 35%);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: all .2s ease-in-out;
}
.g-m_overlay.open {
    opacity: 1;
    pointer-events: all;
    z-index: 10;
}
.g-msb__heading {
    margin: 0;
    color: var(--blue);
    font-size: 18px;
    font-weight: 600;
}
.g-m_side-bar > a {
    color: #000;
    font-size: 12px;
    width: 100%;
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #E1ECEF;
    transition: all .2s ease-in-out;
}
.g-m_side-bar > a.last {
    margin-bottom: 10px;
}
.g-m_side-bar > a:hover {
    color: var(--blue);
    border-bottom-color: var(--blue);
}
/* - Humburger - */
.g-menu__collapse_btn {
    border: none;
    border-radius: 5px;
    background-color: #fff;
    height: 40px;
    width: 50px;
    padding: 10px;
    z-index: 10 ;
}
.g-humburger {
    position: relative;
    height: 2px;
    width: 100%;
    background: var(--blue);
    transition: all .4s ease;
    cursor: pointer;
    z-index: 16;
    display: block;
}
.g-humburger::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 10px;
    border-top: 2px solid var(--blue);
    top: -10px;
    left: 0;
    transition: all .4s ease;
}
.g-humburger::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 10px;
    border-bottom: 2px solid var(--blue);
    top: 0;
    left: 0;
    transition: all .4s ease;
}
.g-humburger.active {
    transform: rotate(45deg);
}
.g-humburger.active::before {
    top: -5px;
    left: 5px;
    transform: rotate(-90deg);
}
.g-humburger.active::after {
    left: -100%;
    opacity: 0;
}

/* Footer Styles */
footer {
    padding: 75px 0 0;
    border-top: 1px solid #E6E6E6;
}
footer .g-container .g-row:first-child {
    margin-bottom: 60px;  
}
.g-footer__search {
    flex: 1;
    margin-right: 15px;
    padding: 36px 23px;
    border-radius: 6px;
    background-color: #f5f5f5;
}
.g-footer__search input {
    width: calc(100% - 44px);
    border: none;
    background-color: transparent;
    padding: 0;
}
.g-social__list {
    margin-left: auto;
}
.g-sl__item {
    width: 96px;
    padding: 15px;
    border-radius: 6px;
    background-color: #f5f5f5;
    text-align: center;
    align-content: center;
    margin-right: 5px;
}
.g-sl__item p {
    margin: 0;
    font-size: 10px;
    color: #9F9F9F;
    width: 100%;
}
.g-sl__item svg {
    width: 20px;
    height: 20px;
    margin-bottom: 15px;
}
.g-mmc__col {
    width: auto;
    max-width: 33%;
}
#footer .g-mmcc_heading {
    margin: 0 0 20px;
    color: var(--blue);
}
.g-mmcc__links-list {
    padding: 0;
    margin: 0;
}
.g-mmcc__links-list li {
    font-size: 11px;
    font-weight: 500;
}
.g-mmcc__links-list li:not(:last-child) {
    margin-bottom: 12px;
}
.g-mmcc__links-list li a {
    color: #000;
}
.g-mmcc__apps {
    margin-bottom: 25px;
}
.g-mmcca__btn:first-child {
    margin-right: 15px;
}
.g-mmcca__btn span {
    width: 100px;
}
.g-mmcc__contact {
    margin-bottom: 25px;
}
.g-mmcc__contact .g-btn {
    height: fit-content;
    margin-right: 15px;
}
.g-mmcc__link {
    font-size: 20px;
}
.g-mmcc__text {
    font-size: 10px;
    color: #9F9F9F;
    margin: 5px 0 0;
}
.g-copyright__list {
    padding: 70px 0 15px;
    width: 100%;
}
.g-cpl__item {
    margin: 0;
    font-size: 10px;
    color: #BEBEBE;
}
.g-cpl__item:not(:last-child) {
    margin-right: 25px;
}

/* Breadcrumbs Styles */
.g-bread__crumbs {
    width: 100%;
    margin: 15px 0 0;
}
.g-bc__item {
    font-size: 10px;
    text-transform: uppercase;
    color: rgb(255 255 255 / 30%);
    margin: 0;
}

/* Pagination Actions Styles */
.g-pagination {
    width: 100%;
    margin-top: 70px;
}
.g-pagination .g-btn.g-btn__gray {
    color: var(--blue);
    font-size: 16px;
    margin-right: 35px;
    margin-bottom: 50px;
}
.g-pagination .g-btn.g-btn__gray span {
    margin-right: 60px;
    font-family: 'Montserrat', sans-serif;
}
.g-pages__list {
    height: 100%;
}
.g-pl__item {
    border: 1px solid #E6E6E6;
    border-radius: 6px;
    color: #B6B6B6;
    font-size: 14px;
    padding: 20px  10px;
    margin-bottom: 50px;
    height: fit-content;
}
.g-pl__item:not(:last-child) {
    margin-right: 10px;
}
.g-pl__item.active {
    background: #F5F5F5;
    border-color: #F5F5F5;
    color: var(--blue);
}


/* ----- MEDIA STYLES ----- */

@media (max-width: 1325px) {
    :root {
        --container: 1140px;
    }
    /* Nav Styles */
    .g-mega-menu {
        margin-right: 20px;
    }
    .g-menu {
        margin: 0 20px;
    }
    .g-menu a {
        margin-right: 15px;
    }
    .g-mm__search {
        left: calc((100vw - var(--container)) / 2 + 90px);
    }
    .g-pe__title {
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 5px;
        color: #000;
    }
}

@media (max-width: 1200px) {
    :root {
        --container: 920px;
    }
    .g-mega-menu {
        margin-right: 0px;
    }
    .g-mega-menu > svg:first-child {
        margin-right: 15px;
    }
    .g-logo {
        width: 100px;
    }
    .g-menu {
        margin: 0 10px;
    }
    .g-menu a {
        margin-right: 15px;
        font-size: 14px;
    }
    .g-auth__item {
        padding: 10px 15px;
        display: inline-block;
    }

    .g-mm__search {
        left: calc((100vw - var(--container)) / 2 + 80px);
        height: 108px;
    }
    .g-mega-menu__content {
        top: 109px;
    }
    .g-mmc__col {
        max-width: 28%;
    }
}

@media (max-width: 992px) {
    :root {
        --container: 768px;
    }

    .g-nav {
        display: none;
    }
    
    .g-nav.mobile {
        display: block;
    }

    /* Glob Styles */
    .g-section__heading {
        font-size: 42px;
    }


    .g-post:not(:nth-child(2n)) {
        margin-right: 15px;
    }
    .g-post {
        width: calc(50% - 15px);
    }





    /* Footer Styles */
    .g-footer__search {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
    }
    .g-social__list {
        width: 100%;
    }
    .g-mmc__col {
        max-width: 50%;
        width: 50%;
        margin-bottom: 30px;
    }
    
}

@media (max-width: 768px) {
    .g-section__actions {
        width: 200px;
    }
    .g-section__heading {
        width: calc(100% - 200px);
        font-size: 34px;
    }



    .g-f__label.w-33 {
        width: calc(50% - 7.5px);
    }
    .g-f__label.w-33:not(:nth-child(3n)) {margin-right: 0;}

    .g-f__label.w-33:not(:nth-child(2n)) {
        margin-right: 15px;
    }


    /* Footer Styles */
    .g-footer__search {
        margin-bottom: 20px;
    }
    .g-mmc__col:last-child {
        max-width: none;
        width: 100%;
    }
    .g-mmcc__apps {
        justify-content: flex-start;
    }
}
@media (max-width: 576px) {
    .g-tn__item:not(.reset) {
        width: calc(50% - 10px);
        max-width: initial;
        margin-bottom: 15px;
    }

    .g-auth {
        padding: 4px 1%;
        background-color: var(--blue);
        border-radius: 5px;
    }


    .g-section__heading {
        width: 100%;
        font-size: 28px;
    }
    .g-section__actions {
        margin-left: auto;
        margin-bottom: 20px;
        width: 100%;
    }
    .g-section__tag {
        padding-left: 44px;
    }
    .g-section__tag::before {
        width: 30px;
    }
    .g-section__heading {
        padding-left: 44px;
    }

    .g-post:not(:nth-child(2n)) {
        margin-right: 0;
    }
    .g-post {
        width: 100%;
    }



    .g-f__label.w-33 {
        width: 100%;
    }
    .g-f__label.w-33:not(:nth-child(2n)), .g-f__label.w-33.mr15 {
        margin-right: 0;
    }



    /* Footer Styles */
    .g-footer__search {
        margin-bottom: 10px;
    }
    .g-mmc__col {
        max-width: none;
        width: 100%;
    }
    .g-mmcc__apps {
        justify-content: flex-start;
    }
    .g-sl__item {
        padding: 15px 10px;
        width: 85px;
    }

    /* PopUp Styles */
    .g-pop_up__wrap {
        padding: 20px 30px;
    }
    .g-pop_up__wrap__header .fa-close {
        top: 0;
    }
    
}